var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"themev2"},[_c('br'),_c('div',{staticClass:"logo-centered"}),(_vm.$route.name == 'LoginSSO' && _vm.$store.getters.isAuthenticated)?_c('div',{staticClass:"w560",staticStyle:{"padding":"50px"}},[_vm._v(" You are already logged in on the INSEAD XR platform. "),_c('br'),_vm._v(" Signing you in and redirecting you to the portal... ")]):_c('div',{staticClass:"w560"},[(_vm.$store.state.auth.status != 'login_success')?_c('ValidationObserver',{ref:"observer",staticClass:"w300",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{ref:"form",class:{disabled: _vm.$store.getters.loading},attrs:{"method":"POST","spellcheck":"false"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[(_vm.$store.state.auth.error)?_c('div',{staticClass:"formerror"},[_vm._v(_vm._s(_vm.$store.state.auth.error))]):_vm._e(),(_vm.$store.state.auth.status == 'pwd_reset_success')?_c('div',{staticClass:"formsuccess"},[_c('span',{staticClass:"checkmark"}),_vm._v(" New password set ")]):_vm._e(),(_vm.$store.state.auth.status == 'register_success_approved')?_c('div',{staticClass:"formsuccess"},[_c('span',{staticClass:"checkmark"}),_vm._v(" Registration successful, you can log in ")]):_vm._e(),(_vm.$store.state.auth.error == 'Email address not confirmed.')?_c('div',[_c('p',[_vm._v("Didn't receive email to confirm your email, or the link expired?")]),_c('center',[_c('a',{staticStyle:{"width":"100%","display":"inline-block"},attrs:{"href":("/confirmemail?email=" + (encodeURIComponent(_vm.form.email)) + "&token=expired")},on:{"click":_vm.confirmEmail}},[_vm._v("Click here to verify your email")])]),_c('br')],1):_vm._e(),_c('ValidationProvider',{staticClass:"inputrow",attrs:{"name":"email","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{staticClass:"emaillabel",attrs:{"for":"email"}},[_vm._v("Email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],ref:"email",class:classes,attrs:{"name":"email","type":"email","novalidate":"","disabled":_vm.$store.getters.loading},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('ValidationProvider',{ref:"password",staticClass:"inputrow",attrs:{"name":"password","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var classes = ref.classes;
var errors = ref.errors;
return [_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('router-link',{staticClass:"passwordresetlink",attrs:{"to":"/passwordreset"}},[_vm._v("Forgot password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"}],class:classes,attrs:{"name":"password","type":"password","novalidate":"","placeholder":"","disabled":_vm.$store.getters.loading},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"errormessage"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)}),_c('br'),_c('button',{staticClass:"full",class:{disabled: _vm.$store.getters.loading},attrs:{"type":"submit","disabled":_vm.$store.getters.loading}},[_vm._v("Login")]),_c('center',[_c('br'),_c('router-link',{attrs:{"to":"/register"}},[_vm._v("Or create new account")])],1),(_vm.$store.getters.loading)?_c('span',{staticClass:"spinner"}):_vm._e()],1)]}}],null,false,3342421750)}):_vm._e()],1),_c('Snackbar',{ref:"snackbar"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }