<template>  
<div class="themev2">
  <br>  
  <div class="logo-centered"></div>

  <div v-if="$route.name == 'LoginSSO' && $store.getters.isAuthenticated" class="w560" style="padding:50px;">
    You are already logged in on the INSEAD XR platform. 
    <br>
    Signing you in and redirecting you to the portal...
  </div>
  <div v-else class="w560">
    <ValidationObserver v-slot="{ invalid, handleSubmit}" tag="div" class="w300" ref="observer" v-if="$store.state.auth.status != 'login_success'">
      <form ref="form" method="POST" @submit.prevent="handleSubmit(onSubmit)" spellcheck="false" :class="{disabled: $store.getters.loading}">        

        <div class="formerror" v-if="$store.state.auth.error">{{$store.state.auth.error}}</div>
        <div class="formsuccess" v-if="$store.state.auth.status == 'pwd_reset_success'">
          <span class="checkmark"> </span> New password set
        </div>
        <div class="formsuccess" v-if="$store.state.auth.status == 'register_success_approved'">
          <span class="checkmark"> </span> Registration successful, you can log in
        </div>

        <div v-if="$store.state.auth.error == 'Email address not confirmed.'">
          <p>Didn't receive email to confirm your email, or the link expired?</p>          
          <center><a @click="confirmEmail" :href="`/confirmemail?email=${encodeURIComponent(form.email)}&token=expired`" style="width: 100%; display: inline-block;">Click here to verify your email</a></center>    
          <br>
        </div>

        <ValidationProvider name="email" rules="required" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="email" class="emaillabel">Email</label>
          <input v-model="form.email" name="email" type="email" ref="email" novalidate :class="classes" :disabled="$store.getters.loading">          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <ValidationProvider name="password" ref="password" rules="required" tag="div" class="inputrow" v-slot="{ classes, errors }">
          <label for="password">Password</label>
          <router-link class="passwordresetlink" to="/passwordreset">Forgot password</router-link>
          <input v-model="form.password" name="password" type="password" novalidate :class="classes" placeholder="" :disabled="$store.getters.loading" />          
          <span class="errormessage">{{ errors[0] }}</span>
        </ValidationProvider>

        <br>        
        <button class="full" type="submit" :class="{disabled: $store.getters.loading}" :disabled="$store.getters.loading">Login</button>

        <center>
          <br>  
          <router-link to="/register">Or create new account</router-link>
        </center>

        <span class="spinner" v-if="$store.getters.loading" />

      </form>
    </ValidationObserver> 
  </div>

  <Snackbar ref="snackbar" /> 
</div>
</template>

<script>
import { required } from 'vee-validate/dist/rules.umd';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import {AUTH_REQUEST} from "../store/actions/auth";
import Snackbar from '@/components/Snackbar.vue'
import axios from 'axios'

extend('required', {...required, message:'Field is required.'});

export default {
  name: 'Login',
  data: function(){
    return {
      form: {
        email: '',
        password: ''
      }   
    }
  },
  computed: {    
  },
  methods: {  
    async onSubmit() {     
      await this.$store.dispatch(AUTH_REQUEST, this.form);      
    },
    confirmEmail(ev){      
      if(!this.form.email){        
        this.$refs.email.focus()
        this.$refs.snackbar.show('Please fill in your email that you would like to verify')
        ev.preventDefault()        
      }
    }  
  },
  components: {
    ValidationProvider, 
    ValidationObserver,
    Snackbar
  },
  async mounted(){
    if(this.$route.query.email){
      this.form.email = this.$route.query.email;
      this.$router.replace("/login")
    } 
    if(this.$route.name == 'LoginSSO' && this.$store.getters.isAuthenticated){      
      this.$refs.snackbar.show('Logging you in to the portal...')
      let resp = await axios.post('auth/sso')
      console.log("redirecting to drupal")
      window.location = (this.$route.query.botiLocal ? 'https://avris-portal.ddev.site:4443/' : process.env.VUE_APP_PORTAL_URL) + 'api/user/jwt' + `?code=${resp.data.code}&redirectPath=${encodeURIComponent(this.$route.query.redirectPath)}`
    }   
  }
}
</script>

<style lang="scss">

.emaillabel{
  margin-top: none;
}

.passwordresetlink{
  font-size: 12px;
  margin-top: 16px;
  padding: 0;
  float: right;

  &:hover{
    background: none;
  }
}

</style>